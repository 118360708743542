var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formulateSupplier" },
    [
      _c(
        "b-row",
        [
          !_vm.isReadOnly
            ? _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("e-person-search", {
                    attrs: {
                      "is-supplier": "",
                      label: _vm.$t("Fornecedor"),
                      required: "",
                      "default-options": _vm.supplierDefaultOptions,
                    },
                    on: { input: _vm.onSelectSupplier },
                    model: {
                      value: _vm.purchaseOrder.supplierId,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "supplierId", $$v)
                      },
                      expression: "purchaseOrder.supplierId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-name",
                  type: "label",
                  label: _vm.$t("Nome"),
                },
                model: {
                  value: _vm.supplier.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "name", $$v)
                  },
                  expression: "supplier.name",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-document",
                  type: "label",
                  filter: "cpfCnpj",
                  "filter-params": [true],
                  disabled: "",
                  label: _vm.$t("CNPJ"),
                },
                model: {
                  value: _vm.supplier.document,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "document", $$v)
                  },
                  expression: "supplier.document",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-state-registration",
                  type: "label",
                  filter: "rgIeMask",
                  label: _vm.$t("Inscrição Estadual"),
                },
                model: {
                  value: _vm.supplier.stateRegistration,
                  callback: function ($$v) {
                    _vm.$set(_vm.supplier, "stateRegistration", $$v)
                  },
                  expression: "supplier.stateRegistration",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "5" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "supplier-supplier-phone",
                  value: _vm.supplierPhone,
                  type: "label",
                  filter: "phone",
                  label: _vm.$t("Telefone"),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.supplier.address
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c("e-address", {
                    attrs: { value: _vm.supplier.address, "read-only": true },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isReadOnly
        ? _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c(
                "b-col",
                { attrs: { md: "12", align: "right" } },
                [
                  _c("e-button", {
                    attrs: {
                      id: "create_supplier",
                      type: "button",
                      variant: "primary",
                      icon: "plus-circle",
                      text: _vm.$t("Novo fornecedor"),
                      disabled: !_vm.$can("Create", "Supplier"),
                      title: !_vm.$can("Create", "Supplier")
                        ? _vm.$t(
                            "Você não possui permissão para criar fornecedor"
                          )
                        : "",
                    },
                    on: { click: _vm.createSupplier },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }