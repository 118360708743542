<template>
  <FormulateForm ref="formulateSupplier">
    <b-row>
      <b-col
        v-if="!isReadOnly"
        md="12"
      >
        <e-person-search
          v-model="purchaseOrder.supplierId"
          is-supplier
          :label="$t('Fornecedor')"
          required
          :default-options="supplierDefaultOptions"
          @input="onSelectSupplier"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="supplier-name"
          v-model="supplier.name"
          type="label"
          :label="$t('Nome')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="supplier-document"
          v-model="supplier.document"
          type="label"
          filter="cpfCnpj"
          :filter-params="[true]"
          disabled
          :label="$t('CNPJ')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="supplier-state-registration"
          v-model="supplier.stateRegistration"
          type="label"
          filter="rgIeMask"
          :label="$t('Inscrição Estadual')"
        />
      </b-col>
      <b-col md="5">
        <FormulateInput
          id="supplier-supplier-phone"
          :value="supplierPhone"
          type="label"
          filter="phone"
          :label="$t('Telefone')"
        />
      </b-col>
    </b-row>

    <b-row v-if="supplier.address">
      <b-col md="12">
        <e-address
          :value="supplier.address"
          :read-only="true"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="!isReadOnly"
      class="mt-1"
    >
      <b-col
        md="12"
        align="right"
      >
        <e-button
          id="create_supplier"
          type="button"
          variant="primary"
          :icon="'plus-circle'"
          :text="$t('Novo fornecedor')"
          :disabled="!$can('Create', 'Supplier')"
          :title="
            !$can('Create', 'Supplier') ? $t('Você não possui permissão para criar fornecedor') : ''
          "
          @click="createSupplier"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import EAddress from '@/views/components/EAddress.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EButton from '@/views/components/EButton.vue'

export default {
  name: 'SupplierForm',

  components: {
    BCol,
    BRow,
    EAddress,
    EPersonSearch,
    EButton,
  },

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },

    purchaseOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    newSupplier() {
      return !!this.purchaseOrder?.supplier?.id
    },
    supplier() {
      return this.purchaseOrder?.supplier
    },
    supplierPhone() {
      return this.purchaseOrder?.supplier?.telephone?.number
    },

    supplierDefaultOptions() {
      const supplier = this.purchaseOrder?.supplier
      if (supplier && Object.keys(supplier).length > 0) return [supplier]
      return []
    },
  },

  methods: {
    ...mapActions('pages/purchase/purchaseOrder/purchaseOrderMaintain', [
      'setComboSupplierAddress',
      'setSupplier',
    ]),

    async onSelectSupplier(val) {
      if (!val) {
        this.$emit('clean-supplier')
        return
      }
      this.$emit('get-supplier-by-id', val)
    },

    async validate() {
      this.$refs.formulateSupplier.showErrors()
      return this.$refs.formulateSupplier.hasErrors
    },

    async createSupplier() {
      this.$router.push({
        name: 'supplier-add',
        params: {
          callbackRouteName: 'purchase-order-add-manual',
        },
      })
    },
  },
}
</script>

<style></style>
