var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.isXml && !_vm.readOnly
        ? _c(
            "b-row",
            { staticClass: "mb-1" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-end" },
                [
                  _c("e-button", {
                    attrs: {
                      text: _vm.$t("Adicionar pagamento"),
                      variant: "primary",
                    },
                    on: { click: _vm.onAddPayment },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "FormulateForm",
                {
                  ref: "formulatePayments",
                  attrs: { name: "formulatePayments" },
                },
                [
                  _c("b-table", {
                    ref: "productTable",
                    staticClass: "bordered",
                    attrs: {
                      "show-empty": "",
                      striped: "",
                      fields: _vm.fields,
                      items: _vm.payments,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(paymentMethod)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? _c("FormulateInput", {
                                  attrs: {
                                    id:
                                      "payments-payment-method-id-" + row.index,
                                    type: "vue-select",
                                    placeholder: _vm.$t(
                                      "Clique para selecionar"
                                    ),
                                    validation: "required",
                                    "validation-messages": {
                                      required: _vm.$t(
                                        "Este campo é obrigatório"
                                      ),
                                    },
                                    options: _vm.paymentMethodsOptions,
                                  },
                                  model: {
                                    value: row.item.paymentMethodId,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "paymentMethodId", $$v)
                                    },
                                    expression: "row.item.paymentMethodId",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(row.item.paymentMethod.name)),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(account)",
                        fn: function (row) {
                          return [
                            _c("e-management-account", {
                              attrs: {
                                id: "payments-account-" + row.index,
                                name:
                                  "paymentAccountsManagementAccount" +
                                  row.index,
                                required: "",
                                label: "",
                                "validation-messages": {
                                  required: _vm.$t("Este campo é obrigatório"),
                                },
                                "is-read-only": _vm.readOnly,
                              },
                              model: {
                                value: row.item.managementAccountId,
                                callback: function ($$v) {
                                  _vm.$set(row.item, "managementAccountId", $$v)
                                },
                                expression: "row.item.managementAccountId",
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "cell(dueDate)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? _c("FormulateInput", {
                                  attrs: {
                                    id: "payments-due-date-" + row.index,
                                    type: "datepicker",
                                    validation: "required",
                                    "validation-messages": {
                                      required: _vm.$t(
                                        "Este campo é obrigatório"
                                      ),
                                    },
                                  },
                                  model: {
                                    value: row.item.dueDate,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "dueDate", $$v)
                                    },
                                    expression: "row.item.dueDate",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("date")(row.item.dueDate))
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(observation)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? _c("FormulateInput", {
                                  staticClass: "transparent-input",
                                  attrs: {
                                    id: "payments-observation-" + row.index,
                                    type: "textarea",
                                    placeholder: _vm.$t(
                                      "Clique informar uma observação"
                                    ),
                                  },
                                  model: {
                                    value: row.item.observation,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "observation", $$v)
                                    },
                                    expression: "row.item.observation",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(_vm._s(row.item.observation || "-")),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(value)",
                        fn: function (row) {
                          return [
                            !_vm.readOnly
                              ? _c("FormulateInput", {
                                  staticClass: "text-right",
                                  attrs: {
                                    id: "payments-value-" + row.index,
                                    type: "text-number",
                                    currency: "R$",
                                    precision: 3,
                                    validation: "required|min:0.01",
                                    "validation-messages": {
                                      required: _vm.$t(
                                        "Este campo é obrigatório"
                                      ),
                                      min: _vm.$t(
                                        "Este campo deve ser maior que 0.01"
                                      ),
                                    },
                                  },
                                  model: {
                                    value: row.item.value,
                                    callback: function ($$v) {
                                      _vm.$set(row.item, "value", $$v)
                                    },
                                    expression: "row.item.value",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("currency")(row.item.value))
                                  ),
                                ]),
                          ]
                        },
                      },
                      {
                        key: "cell(action)",
                        fn: function (row) {
                          return [
                            _c("e-grid-actions", {
                              attrs: {
                                "show-update": false,
                                "show-delete": true,
                              },
                              on: {
                                delete: function ($event) {
                                  return _vm.onRemovePayment(row.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }