var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "formulateInvoice" },
    [
      _vm.isReadOnly
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-invoice-number",
                      value: _vm.purchaseOrder.invoiceNumber,
                      type: "label",
                      filter: "segments",
                      "filter-params": [3, ".", 9, "0"],
                      label: _vm.$t("Número"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-invoice-series",
                      value: _vm.purchaseOrder.invoiceSeries,
                      type: "label",
                      filter: "padLeft",
                      "filter-params": [3, "0"],
                      label: _vm.$t("Série"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-invoice-date",
                      value: _vm.purchaseOrder.invoiceDate,
                      type: "label",
                      filter: "date",
                      label: _vm.$t("Data Emissão"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-total-value",
                      value: _vm.purchaseOrder.totalValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Valor da Nota"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-products-value",
                      value: _vm.purchaseOrder.productsValue,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Valor dos Produtos"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-freight",
                      value: _vm.purchaseOrder.freight,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Valor do Frete"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-insurance",
                      value: _vm.purchaseOrder.insurance,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Valor do Seguro"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-discount",
                      value: _vm.purchaseOrder.discount,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Descontos"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-other-costs",
                      value: _vm.purchaseOrder.otherCosts,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Outros Custos"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-taxes",
                      value: _vm.taxes,
                      type: "label",
                      filter: "currency",
                      label: _vm.$t("Impostos"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "barcode",
                    {
                      staticClass: "text-center",
                      staticStyle: { overflow: "hidden" },
                      attrs: {
                        id: "invoice-invoice-key",
                        value: _vm.purchaseOrder.invoiceKey,
                        height: "50",
                      },
                    },
                    [_vm._v(" Invoice Key ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "invoice-invoice-number",
                      type: "text-mask",
                      mask: ["###.###.###"],
                      label: _vm.$t("Número"),
                      validation: "required",
                    },
                    model: {
                      value: _vm.purchaseOrder.invoiceNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "invoiceNumber", $$v)
                      },
                      expression: "purchaseOrder.invoiceNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "invoice-invoice-series",
                      type: "number",
                      label: _vm.$t("Série"),
                      validation: "required",
                    },
                    model: {
                      value: _vm.purchaseOrder.invoiceSeries,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "invoiceSeries", $$v)
                      },
                      expression: "purchaseOrder.invoiceSeries",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "invoice-invoice-date",
                      type: "datepicker",
                      placeholder: _vm.$t("dd/mm/aaaa"),
                      label: _vm.$t("Data Emissão"),
                      validation: "required",
                    },
                    model: {
                      value: _vm.purchaseOrder.invoiceDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "invoiceDate", $$v)
                      },
                      expression: "purchaseOrder.invoiceDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "invoice-total-value",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Valor da Nota"),
                      validation: "required",
                    },
                    model: {
                      value: _vm.purchaseOrder.totalValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "totalValue", $$v)
                      },
                      expression: "purchaseOrder.totalValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "invoice-products-value",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Valor dos Produtos"),
                      validation: "required",
                    },
                    model: {
                      value: _vm.purchaseOrder.productsValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "productsValue", $$v)
                      },
                      expression: "purchaseOrder.productsValue",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-freight",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Valor do Frete"),
                    },
                    model: {
                      value: _vm.purchaseOrder.freight,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "freight", $$v)
                      },
                      expression: "purchaseOrder.freight",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-insurance",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Valor do Seguro"),
                    },
                    model: {
                      value: _vm.purchaseOrder.insurance,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "insurance", $$v)
                      },
                      expression: "purchaseOrder.insurance",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-discount",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Descontos"),
                    },
                    model: {
                      value: _vm.purchaseOrder.discount,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "discount", $$v)
                      },
                      expression: "purchaseOrder.discount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-other-costs",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Outros Custos"),
                    },
                    model: {
                      value: _vm.purchaseOrder.otherCosts,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "otherCosts", $$v)
                      },
                      expression: "purchaseOrder.otherCosts",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "invoice-taxes",
                      type: "text-number",
                      currency: "R$",
                      precision: 2,
                      label: _vm.$t("Impostos"),
                    },
                    model: {
                      value: _vm.purchaseOrder.totalTaxes,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchaseOrder, "totalTaxes", $$v)
                      },
                      expression: "purchaseOrder.totalTaxes",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _c(
                    "barcode",
                    {
                      staticClass: "text-center",
                      staticStyle: { overflow: "hidden" },
                      attrs: {
                        id: "invoice-invoice-key",
                        value: _vm.purchaseOrder.invoiceKey || 123,
                        height: "50",
                      },
                    },
                    [_vm._v(" Invoice Key ")]
                  ),
                  _c("FormulateInput", {
                    staticClass: "mt-1 required",
                    attrs: {
                      id: "invoice-input-invoice-key",
                      label: _vm.$t("Código de Barras da NF"),
                      type: "text",
                      inputmode: "numeric",
                      validation: "required",
                      pattern: /[0-9]*/,
                    },
                    model: {
                      value: _vm.purchaseOrder.invoiceKey,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchaseOrder,
                          "invoiceKey",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "purchaseOrder.invoiceKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }