<template>
  <section>
    <b-row
      v-if="!isXml && !readOnly"
      class="mb-1"
    >
      <b-col class="d-flex justify-content-end">
        <e-button
          :text="$t('Adicionar pagamento')"
          variant="primary"
          @click="onAddPayment"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <FormulateForm
          ref="formulatePayments"
          name="formulatePayments"
        >
          <b-table
            ref="productTable"
            show-empty
            striped
            class="bordered"
            :fields="fields"
            :items="payments"
          >
            <template #cell(paymentMethod)="row">
              <FormulateInput
                v-if="!readOnly"
                :id="`payments-payment-method-id-${row.index}`"
                v-model="row.item.paymentMethodId"
                type="vue-select"
                :placeholder="$t('Clique para selecionar')"
                validation="required"
                :validation-messages="{
                  required: $t('Este campo é obrigatório'),
                }"
                :options="paymentMethodsOptions"
              />
              <span v-else>{{ row.item.paymentMethod.name }}</span>
            </template>
            <template #cell(account)="row">
              <e-management-account
                :id="`payments-account-${row.index}`"
                v-model="row.item.managementAccountId"
                :name="`paymentAccountsManagementAccount${row.index}`"
                required
                :label="''"
                :validation-messages="{
                  required: $t('Este campo é obrigatório'),
                }"
                :is-read-only="readOnly"
              />
            </template>
            <template #cell(dueDate)="row">
              <FormulateInput
                v-if="!readOnly"
                :id="`payments-due-date-${row.index}`"
                v-model="row.item.dueDate"
                type="datepicker"
                validation="required"
                :validation-messages="{
                  required: $t('Este campo é obrigatório'),
                }"
              />
              <span v-else>{{ row.item.dueDate | date }}</span>
            </template>
            <template #cell(observation)="row">
              <FormulateInput
                v-if="!readOnly"
                :id="`payments-observation-${row.index}`"
                v-model="row.item.observation"
                type="textarea"
                class="transparent-input"
                :placeholder="$t('Clique informar uma observação')"
              />
              <span v-else>{{ row.item.observation || '-' }}</span>
            </template>
            <template #cell(value)="row">
              <FormulateInput
                v-if="!readOnly"
                :id="`payments-value-${row.index}`"
                v-model="row.item.value"
                type="text-number"
                currency="R$"
                :precision="3"
                class="text-right"
                validation="required|min:0.01"
                :validation-messages="{
                  required: $t('Este campo é obrigatório'),
                  min: $t('Este campo deve ser maior que 0.01'),
                }"
              />
              <span v-else>{{ row.item.value | currency }}</span>
            </template>

            <template #cell(action)="row">
              <e-grid-actions
                :show-update="false"
                :show-delete="true"
                @delete="onRemovePayment(row.item)"
              />
            </template>
          </b-table>
        </FormulateForm>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BTable, BRow, BCol } from 'bootstrap-vue'
import { accountTypes, paymentTypes } from '@/mixins'
import { mapGetters } from 'vuex'
import EGridActions from '@/views/components/EGridActions.vue'
import EButton from '@/views/components/EButton.vue'
import EManagementAccount from '@/views/components/inputs/EManagementAccount.vue'

export default {
  components: { BTable, BRow, BCol, EGridActions, EButton, EManagementAccount },
  mixins: [paymentTypes, accountTypes],

  props: {
    isXml: {
      type: Boolean,
      default: false,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    ...mapGetters('pages/purchase/purchaseOrder', ['managementAccountsOptions']),
    ...mapGetters('pages/purchase/purchaseOrder/purchaseOrderMaintain', ['readOnly']),
    ...mapGetters('app', ['paymentMethodsOptions']),

    payments() {
      return this.purchaseOrder?.payments?.filter(p => !p.isDeleted) || []
    },
    paymentMethod() {
      const [firstPayment] = this.payments
      return firstPayment?.paymentMethod?.name || { name: '-' }
    },

    fields() {
      return [
        {
          label: this.$t('Ação'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '80px' },
          hide: this.isXml || this.readOnly,
        },
        {
          label: this.$t('Forma de Pagamento'),
          key: 'paymentMethod',
          class: 'text-center',
          thStyle: 'width: 220px;',
        },
        {
          label: this.$t('Conta Gerencial'),
          key: 'account',
          class: 'text-center',
          thStyle: 'width: 320px;',
        },
        {
          label: this.$t('Parcela'),
          key: 'installment',
          class: 'text-center',
          thStyle: 'width: 100px;',
        },
        {
          label: this.$t('Vencimento'),
          key: 'dueDate',
          class: 'text-center',
          formatter: value => this.$options.filters.date(value),
          thStyle: 'width: 170px;',
        },
        {
          label: this.$t('Observação'),
          key: 'observation',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          formatter: value => this.$options.filters.currency(value),
          thStyle: 'width: 180px;',
        },
      ].filter(f => !f.hide)
    },
  },

  mounted() {},

  methods: {
    onAddPayment() {
      this.$emit('add-payment')
    },

    async onRemovePayment(payment) {
      if (
        await this.confirm({
          icon: 'question',
          text: this.$t('Tem certeza que deseja remover este item?'),
        })
      ) {
        this.$emit('remove-payment', payment)
      }
    },

    validateForm() {
      this.$refs.formulatePayments.showErrors()
      return this.$refs.formulatePayments.hasErrors
    },
  },
}
</script>

<style scoped></style>
