<template>
  <section>
    <div class="mb-2">
      <b-row
        v-if="!isXml && !readOnly"
        class="mb-1"
      >
        <b-col class="d-flex justify-content-end">
          <e-button
            :text="$t('Adicionar item')"
            variant="primary"
            @click="onAddItem"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <FormulateForm ref="formulateOrderItems">
            <b-table
              ref="productTable"
              show-empty
              striped
              class="bordered"
              :fields="fields"
              :items="items"
              style="max-height: 500px !important"
            >
              <template
                v-if="isXml"
                #thead-top
              >
                <b-tr>
                  <!-- <b-th style="background-color: transparent; border: 0">
                  <span class="sr-only">Ações</span>
                </b-th> -->
                  <b-th
                    variant="primary"
                    colspan="3"
                  >
                    {{ $t('Produto da Nota Fiscal') }}
                  </b-th>
                  <b-th
                    variant="primary"
                    colspan="4"
                    class="echope-product"
                  >
                    {{ $t('Produto eChope') }}
                  </b-th>
                  <b-th
                    variant="primary"
                    colspan="2"
                  >
                    {{ $t('Valores') }}
                  </b-th>
                </b-tr>
              </template>

              <template #cell(erpSku_name)="{ item, index }">
                <div
                  v-if="!readOnly && (!item.erpSku.sku || !item.erpSku.sku.name)"
                  style="display: flex; margin-right: 5px"
                >
                  <div style="display: flex; align-items; center; margin: 3px 0;">
                    <e-button
                      icon="plus"
                      variant="success"
                      class="p-5"
                      :title="$t('Criar novo produto')"
                      @click="createNewProduct(item, index)"
                    />
                  </div>
                  <div style="width: 100%">
                    <FormulateInput
                      :id="`order-items-sku-searched-${index}`"
                      v-model="item.erpSku.skuSearched"
                      type="select-searchable"
                      :placeholder="$t('Digite para pesquisar')"
                      :options="skusOptions"
                      autofocus
                      :instruction="
                        $t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa')
                      "
                      style="width: 100%; min-width: 200px; display: inline-block"
                      class="ml-50"
                      @fetchSearch="fetchSearchSku"
                      @input="selectSku(item, item.erpSku.skuSearched)"
                    />
                  </div>
                </div>
                <div
                  v-else
                  style="display: flex"
                >
                  <div
                    v-if="!readOnly || (!readOnly && isXml)"
                    style="display: flex; align-items; center; margin: 3px 0;"
                  >
                    <e-button
                      icon="trash"
                      variant="danger"
                      class="p-5"
                      :title="$t('Remover associação')"
                      @click="deleteSkuAssociation(item.erpSku)"
                    />
                  </div>

                  <e-product-router-link
                    :sku-id="item.erpSku.sku.id"
                    :product-id="item.erpSku.sku.productId"
                    :product-name="item.erpSku.sku.name"
                    :ean="item.erpSku.sku.ean"
                  />
                </div>
              </template>

              <template #cell(erpSku_prices)="row">
                <p>
                  <span class="font-weight-bold"> {{ $t('Preço Custo') }} </span> <br>
                  <span class="pb-1">{{
                    (row.item.erpSku ? row.item.erpSku.averagePrice : null) | currency('-')
                  }}</span>
                </p>

                <p class="mb-0">
                  <span class="font-weight-bold"> {{ $t('Preço venda') }} </span> <br>
                  <span>{{
                    (row.item.erpSku ? row.item.erpSku.price : null) | currency('-')
                  }}</span>
                </p>
              </template>

              <template #cell(erpSku_unitOfMeasurement)="row">
                <span>{{
                  row.item.erpSku.sku ? row.item.erpSku.sku.unitOfMeasurement || '-' : '-'
                }}</span>
              </template>

              <template #cell(erpSku_quantity)="row">
                <div style="display: flex; flex-direction: column; align-items: center;">
                  <FormulateInput
                    v-if="!readOnly"
                    :id="`order-items-quantity-${row.index}`"
                    v-model="row.item.erpSku.quantity"
                    type="text-number"
                    :precision="3"
                    class="text-right"
                    :title="$t('Clique para editar')"
                    validation="required|min:0.001"
                    :validation-messages="{
                      required: $t('Obrigatório'),
                      min: $t('Deve ser maior que zero'),
                    }"
                    style="width: 80px"
                    @input="(v) => onInputErpQuantity(row.item)"
                  />
                  <span v-else>{{ row.item.erpSku.quantity | number(3) }}</span>
                </div>
              </template>

              <template #cell(erpSku_unitValue)="row">
                <span v-if="!validUnitValue(row.item)">
                  <e-instructions
                    container-class="mr-0"
                    :title="$t('Validação')"
                    :target="`instruction_validation_${row.index}`"
                    :instruction="`${$t(
                      'Valor unitário possui diferença de 5% em relação ao preço de custo do produto no sistema.'
                    )}`"
                  />
                  <br>
                </span>

                <template v-if="isXml">
                  {{ (row.item.totalValue / row.item.erpSku.quantity) | currency }}
                </template>
                <template v-else>
                  <FormulateInput
                    v-if="!readOnly"
                    :id="`order-items-unit_price-${row.index}`"
                    v-model="row.item.unitValue"
                    type="text-number"
                    currency="R$"
                    :precision="3"
                    class="text-right"
                    :title="$t('Clique para editar')"
                    validation="required|min:0.01"
                    :validation-messages="{
                      required: $t('Obrigatório'),
                      min: $t('Deve ser maior que 0.01'),
                    }"
                    style="width: 120px"
                    @input="(v) => onInputErpUnitValue(row.item)"
                  />
                  <span v-else>
                    {{ row.item.unitValue | currency }}
                  </span>
                </template>
              </template>

              <template #cell(action)="row">
                <e-grid-actions
                  :show-update="false"
                  :show-delete="true"
                  @delete="onRemoveItem(row.item)"
                />
              </template>
            </b-table>
          </FormulateForm>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { BRow, BCol, BTable, BTr, BTh } from 'bootstrap-vue'
import { EButton, EProductRouterLink, EGridActions } from '@/views/components'
import { alerts } from '@/mixins'
import {
  getInitialPurchaseOrder,
  getInitialPurchaseOrderItem,
} from '@/store/pages/purchase/purchase-order/purchase-order-maintain'
import { roundDecimal } from '@/utils/number-utils'
import EInstructions from '@/views/components/EInstructions.vue'

export default {
  name: 'OrderItems',

  components: {
    BRow,
    BCol,
    BTable,
    BTr,
    BTh,
    EButton,
    EGridActions,
    EProductRouterLink,
    EInstructions,
  },

  mixins: [alerts],

  props: {
    isXml: {
      type: Boolean,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      saving: false,
      productOptions: [],
      updateIndex: null,
      sku: null,
      skus: [],
      localPurchaseOrder: getInitialPurchaseOrder(),
    }
  },

  computed: {
    ...mapGetters('pages/purchase/purchaseOrder/purchaseOrderMaintain', ['readOnly']),
    skusOptions() {
      return this.skus.map(sku => ({
        ...sku,
        value: sku.id,
        label: sku.name,
      }))
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isXml || this.readOnly,
        },
        {
          label: this.$t('Produto'),
          key: 'xmlProduct.name',
          hide: !this.isXml,
        },
        {
          label: this.$t('Unid.'),
          key: 'xmlProduct.unitOfMeasurement',
          class: 'text-center',
          hide: !this.isXml,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          class: 'text-center',
          formatter: value => this.$options.filters.number(value, 3, true),
          hide: !this.isXml,
        },
        {
          label: this.$t('Produto'),
          key: 'erpSku_name',
          thClass: 'echope-product',
          thStyle: { width: '350px' },
          // thStyle: 'background-color: lightgray',
        },
        {
          label: this.$t('Preços'),
          key: 'erpSku_prices',
          class: 'text-center',
          thClass: 'echope-product',
          // thStyle: 'background-color: lightgray',
        },
        {
          label: this.$t('Unid.'),
          key: 'erpSku_unitOfMeasurement',
          class: 'text-center',
          thClass: 'echope-product',
          // thStyle: 'background-color: lightgray',
        },
        {
          label: this.$t('Qtd.'),
          key: 'erpSku_quantity',
          class: 'text-center',
          thClass: 'echope-product',
          // thStyle: 'background-color: lightgray',
        },
        {
          label: this.$t('Vlr. Unit.'),
          key: 'erpSku_unitValue',
          class: 'text-center',
          tdClass: (value, key, item) => (this.validUnitValue(item) ? '' : 'table-danger'),
        },
        {
          label: this.$t('Vlr. Total'),
          key: 'totalValue',
          class: 'text-center',
          formatter: value => this.$options.filters.currency(value),
        },
      ].filter(a => !a.hide)
    },
    items() {
      return this.purchaseOrder?.items.filter(i => !i.isDeleted)
    },
  },

  methods: {
    createNewProduct(productItem, index) {
      this.$router.push({
        name: 'purchase-order-xml-import-add-product',
        params: {
          callbackRouteName: this.$route.name,
          purchaseOrderData: {
            purchaseOrder: this.purchaseOrder,
            itemIndex: index,
          },
        },
      })
    },

    selectSku(product, skuIdSelected) {
      // eslint-disable-next-line no-param-reassign
      product.erpSku.sku = this.skus.find(sku => sku.id === parseInt(skuIdSelected, 10))

      if (!this.readOnly) {
        this.$emit('update-average-prices')
      }
    },

    async deleteSkuAssociation(sku) {
      if (
        await this.confirm({
          icon: 'question',
          text: this.$t('Tem certeza que deseja remover esta associação?'),
        })
      ) {
        const { erpSku } = getInitialPurchaseOrderItem()
        /* eslint-disable no-param-reassign */
        sku.sku = null
        sku.skuSearched = null
        sku.validationAvgPrice = erpSku.validationAvgPrice
        sku.price = erpSku.price
        sku.averagePrice = erpSku.averagePrice
        /* eslint-disable no-param-reassign */
      }
    },

    async fetchSearchSku(loading, search) {
      try {
        loading(true)
        this.skus = (
          await this.$http.get('/api/skus/search', {
            params: {
              search,
              active: true,
            },
          })
        ).data.results
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },

    onAddItem() {
      this.$emit('add-item')
    },
    async onRemoveItem(item) {
      if (
        await this.confirm({
          icon: 'question',
          text: this.$t('Tem certeza que deseja remover este item?'),
        })
      ) {
        this.$emit('remove-item', item)
      }
    },

    onInputErpQuantity(item) {
      // eslint-disable-next-line no-param-reassign
      item.erpSku.quantityInputMethod = 'Manual'

      if (!this.isXml) {
        // eslint-disable-next-line no-param-reassign
        item.totalValue = roundDecimal(item.erpSku.quantity * item.unitValue, 5)
      }
    },
    onInputErpUnitValue(item) {
      if (!this.isXml) {
        // eslint-disable-next-line no-param-reassign
        item.totalValue = roundDecimal(item.erpSku.quantity * item.unitValue, 5)
      }
    },

    validUnitValue(item) {
      if (this.readOnly || item.erpSku?.averagePrice === null) return true

      const minValue = item.erpSku?.validationAvgPrice?.minValue
      const maxValue = item.erpSku?.validationAvgPrice?.maxValue
      if (_.isNil(minValue) || _.isNil(maxValue)) return true

      const unitValue = this.isXml
        ? roundDecimal(item.totalValue / item.erpSku.quantity, 2)
        : item.unitValue

      return unitValue >= minValue && unitValue <= maxValue
    },

    validateForm() {
      this.$refs.formulateOrderItems.showErrors()
      return this.$refs.formulateOrderItems.hasErrors
    },
  },
}
</script>

<style>
.echope-product {
  background-color: #e7e7e7 !important;
}
</style>
