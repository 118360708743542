<template>
  <FormulateForm ref="formulateInvoice">
    <b-row v-if="isReadOnly">
      <b-col md="3">
        <FormulateInput
          id="invoice-invoice-number"
          :value="purchaseOrder.invoiceNumber"
          type="label"
          filter="segments"
          :filter-params="[3, '.', 9, '0']"
          :label="$t('Número')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-invoice-series"
          :value="purchaseOrder.invoiceSeries"
          type="label"
          filter="padLeft"
          :filter-params="[3, '0']"
          :label="$t('Série')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-invoice-date"
          :value="purchaseOrder.invoiceDate"
          type="label"
          filter="date"
          :label="$t('Data Emissão')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-total-value"
          :value="purchaseOrder.totalValue"
          type="label"
          filter="currency"
          :label="$t('Valor da Nota')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-products-value"
          :value="purchaseOrder.productsValue"
          type="label"
          filter="currency"
          :label="$t('Valor dos Produtos')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-freight"
          :value="purchaseOrder.freight"
          type="label"
          filter="currency"
          :label="$t('Valor do Frete')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-insurance"
          :value="purchaseOrder.insurance"
          type="label"
          filter="currency"
          :label="$t('Valor do Seguro')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-discount"
          :value="purchaseOrder.discount"
          type="label"
          filter="currency"
          :label="$t('Descontos')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-other-costs"
          :value="purchaseOrder.otherCosts"
          type="label"
          filter="currency"
          :label="$t('Outros Custos')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-taxes"
          :value="taxes"
          type="label"
          filter="currency"
          :label="$t('Impostos')"
        />
      </b-col>
      <b-col md="12">
        <barcode
          id="invoice-invoice-key"
          class="text-center"
          style="overflow: hidden"
          :value="purchaseOrder.invoiceKey"
          height="50"
        >
          Invoice Key
        </barcode>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col md="3">
        <FormulateInput
          id="invoice-invoice-number"
          v-model="purchaseOrder.invoiceNumber"
          type="text-mask"
          :mask="['###.###.###']"
          :label="$t('Número')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-invoice-series"
          v-model="purchaseOrder.invoiceSeries"
          type="number"
          :label="$t('Série')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-invoice-date"
          v-model="purchaseOrder.invoiceDate"
          type="datepicker"
          :placeholder="$t('dd/mm/aaaa')"
          :label="$t('Data Emissão')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-total-value"
          v-model="purchaseOrder.totalValue"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor da Nota')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-products-value"
          v-model="purchaseOrder.productsValue"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor dos Produtos')"
          class="required"
          validation="required"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-freight"
          v-model="purchaseOrder.freight"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor do Frete')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-insurance"
          v-model="purchaseOrder.insurance"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Valor do Seguro')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-discount"
          v-model="purchaseOrder.discount"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Descontos')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-other-costs"
          v-model="purchaseOrder.otherCosts"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Outros Custos')"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="invoice-taxes"
          v-model="purchaseOrder.totalTaxes"
          type="text-number"
          currency="R$"
          :precision="2"
          :label="$t('Impostos')"
        />
      </b-col>
      <b-col md="12">
        <barcode
          id="invoice-invoice-key"
          class="text-center"
          style="overflow: hidden"
          :value="purchaseOrder.invoiceKey || 123"
          height="50"
        >
          Invoice Key
        </barcode>
        <FormulateInput
          id="invoice-input-invoice-key"
          v-model.trim="purchaseOrder.invoiceKey"
          class="mt-1 required"
          :label="$t('Código de Barras da NF')"
          type="text"
          inputmode="numeric"
          validation="required"
          :pattern="/[0-9]*/"
        />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import VueBarcode from 'vue-barcode'

export default {
  components: {
    BCol,
    BRow,
    barcode: VueBarcode,
  },

  props: {
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    purchaseOrder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {}
  },

  computed: {
    taxes() {
      return this.purchaseOrder.taxes?.total
    },
  },

  methods: {
    validate() {
      this.$refs.formulateInvoice.showErrors()
      return this.$refs.formulateInvoice.hasErrors
    },
  },
}
</script>

<style></style>
